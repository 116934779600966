import React from 'react';
import {
  Box
} from '@chakra-ui/react';
import FooterTop from '../FooterTop';
import SectionTop from '../../Components/SectionTop';
import AboutTop from './AboutTop';
import AboutImg from './AboutImg.js';
import AboutDets from './AboutDets/index.js';
import AbtImg from "../../Assets/images/AboutImg.png"
const About = () => {
  
  // const Feature = ({ heading, text }) => {
  //   return (
  //     <GridItem>
  //       <chakra.h3 fontSize="xl" fontWeight="600">
  //         {heading}
  //       </chakra.h3>
  //       <chakra.p>{text}</chakra.p>
  //     </GridItem>
  //   );
  // };

  return (
    <Box>
      <SectionTop
        title="About Codise"
        desc="Welcome to CODISE-IT, the leading provider of Managed IT Services for businesses of all sizes. With our comprehensive range."
      />

      <Box>
        <AboutTop />
        <Box align={'center'}
maxW="1200px"
mx="auto">
          <AboutImg image={AbtImg} />
        </Box>
        <AboutDets />
        <FooterTop />
        {/* <Testimonails /> */}
      </Box>
    </Box>
  );
};

export default About;
