import React from "react";
import {
  Box,
  Flex,
  VStack,
  Divider,
  chakra,
  Grid,
  GridItem,
  Container,
} from "@chakra-ui/react";

const AboutDets = () => {
  const aboutData = [
    {
      id: 1,
      title: "Proven expertise",
      desc: "With years of experience and a team of highly skilled professionals, CODISE-IT delivers reliable and effective IT services. Our track record speaks volumes about our ability to solve complex challenges and exceed client expectations.",
    },
    {
      id: 2,
      title: "Customized solutions",
      desc: "We understand that every business is unique. That’s why we design tailored solutions to meet your specific needs, ensuring seamless integration and maximum impact.",
    },
    {
      id: 3,
      title: "Proactive support",
      desc: "Our 24/7 monitoring and support services keep your systems running smoothly. We address potential issues before they become problems, minimizing downtime and disruptions.",
    },
    {
      id: 4,
      title: "Robust security measures",
      desc: "Protecting your business from cyber threats is our priority. From firewalls to advanced threat detection, we implement strong security measures to safeguard your data and systems.",
    },
    {
      id: 5,
      title: "Scalability and growth",
      desc: "Our solutions are designed to grow with your business. Whether you’re a startup or an enterprise, we provide the flexibility to adapt to your evolving needs.",
    },
    {
      id: 6,
      title: "Cost optimization",
      desc: "We help you maximize your IT investments by providing cost-effective solutions that deliver superior value without compromising quality.",
    },
  ];

  return (
    <Container maxW="6xl" mt={10} px={{ base: 4, md: 6 }}>
      {aboutData.map((aboutItem) => (
        <Box key={aboutItem.id} mb={6}>
          <Grid
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
            gap={6}
            alignItems="center"
          >
            {/* Title Section */}
            <GridItem>
              <VStack align={{ base: "center", md: "flex-start" }} spacing={4}>
                <chakra.h1
                  fontSize={{ base: "lg", md: "xl" }}
                  fontWeight="700"
                  textAlign={{ base: "center", md: "start" }}
                >
                  {aboutItem.title}
                </chakra.h1>
              </VStack>
            </GridItem>

            {/* Description Section */}
            <GridItem>
              <Flex>
                <chakra.p
                  fontSize={{ base: "md", md: "lg" }}
                  textAlign={{ base: "center", md: "justify" }}
                  fontWeight="400"
                  lineHeight="1.6"
                >
                  {aboutItem.desc}
                </chakra.p>
              </Flex>
            </GridItem>
          </Grid>

          <Divider my={5} />
        </Box>
      ))}
    </Container>
  );
};

export default AboutDets;
