import React from 'react';
import {
  Box,
  SimpleGrid,
  Stack,
  Flex,
  Text,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';

import Logo from '../../Assets/images/logo.png';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import PdfFile from '../../Assets/Pdf/Website Terms of Use.pdf';
import { SiLinkedin } from 'react-icons/si';
import {
  FaLocationArrow,
  FaEnvelope,
  FaPhone,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'800'} fontSize={'lg'} mb={4}>
      {children}
    </Text>
  );
};

// const Privacypdfdownload = () => {
//   fetch(PdfFile).then(response => {
//     response.blob().then(blob => {
//       const fileURL = window.URL.createObjectURL(blob);
//       let alink = document.createElement('a');
//       alink.href = fileURL;
//       alink.download = 'Terms and Conditions.pdf';
//       alink.click();
//     });
//   });
// };

const Footer = () => {
  const NavlinkList = [
    {
      id:1,
      label: 'Home',
      to: '/',
    },
    {
      id:2,
      label: 'Services',
      to: '/services',
    },
    {
      id:3,
      label: 'Contact',
      to: '/contact',
    },
    {
      id:4,
      label: 'About',
      to: '/about',
    },
    // {
    //   label: 'Blog',
    //   to: '/blog',
    // },
  ];

  const FooterLink = [
    {
      id: 1,
      name: 'Software Development',
      to: '/services/Software%20Development%20and%20Integration',
    },
    {
      id: 2,
      name: 'Network Setup & Management',
      to: '/services/Network%20Setup%20and%20Management',
    },
    {
      id: 3,
      name: 'Help Desk',
      to: '/services/Help%20Desk%20and%20Technical%20Support',
    },
    {
      id: 4,
      name: 'IT Infrastructure Monitoring',
      to: '/services/IT%20Infrastructure%20Monitoring',
    },
    {
      id: 5,
      name: 'Technical Support',
      to: '/services/Help%20Desk%20and%20Technical%20Support',
    },
    {
      id: 6,
      name: 'Data Backup & Recovery',
      to: '/services/Data%20Backup%20and%20Recovery',
    },
    // {
    //   id:7,
    //   name:'Web & Application Development',
    //   to:'/services/Help%20Desk%20and%20Technical%20Support'
    // }
  ];

  const sociallinks = [
    {
      id: 1,
      name: 'Instagram',
      icon: FaInstagram,
      to: 'https://www.instagram.com/codiseofficial/',
    },
    {
      id: 2,
      name: 'Facaebook',
      icon: FaFacebook,
      to: 'https://www.facebook.com/people/Codise/100067447081513/',
    },
    {
      id: 3,
      name: 'LinkedIn',
      icon: SiLinkedin,
      to: 'https://www.linkedin.com/company/codiseofficial/?viewAsMember=true',
    },
  ];

  return (
    <Box
      bgGradient="linear(to-br, #30298E, #000000, #000000, #30298E)"
      color={useColorModeValue('white.700', 'white.200')}
      className="backgrd"
      display={'flex'}
    >
    <Box align={'center'}
    pt={10}
    pb={5}
        maxW="1200px"
        mx="auto" >
      <Stack p={5}>
        <SimpleGrid
          templateColumns={{
            sm: '4fr 2fr',
            md: '4fr 2fr',
            lg: '5fr 1fr 3fr 2fr',
          }}
          gap={'40px'}
        >
          <Flex justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Box maxW={'600px'} textAlign={'justify'} pe={'40px'}>
              <Image w="auto" h={'60px'} src={Logo} alt="Logo" />
              <Text fontSize={'xl'}>A Young Tech Company.</Text>
              <br />
              <Text>
                CODISE-IT, a business brand that not only fulfill customer's
                requirement but provide them efficiency, productivity,
                scalability and security.
              </Text>
              <Flex gap={'20px'} pt={'20px'}>
                {sociallinks.map(link => {
                  return (
                    <Link
                      className="icons"
                      key={link.name}
                      target="_blank"
                      to={link.to}
                    >
                      <link.icon />
                    </Link>
                  );
                })}
              </Flex>
            </Box>
          </Flex>
          <Stack align={'flex-start'}>
            <ListHeader>Information</ListHeader>
            {NavlinkList.map(navItem => {
              return (
                <Link key={navItem.id} to={navItem.to}>
                  {navItem.label}
                </Link>
              );
            })}
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Services</ListHeader>
            {FooterLink.map(footItem => {
              return (
                <Link key={footItem.id} to={footItem.to}>
                  {footItem.name}
                </Link>
              );
            })}
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Contact</ListHeader>
            <Flex as="a" href="mailto:info@codise-it.com">
              <FaEnvelope /> &nbsp; info@codise-it.com
            </Flex>
            <Flex as="a" href="tel:+1 (562) 977-9228">
              <FaPhone /> &nbsp; +1 (562) 977-9228
            </Flex>
            <br />
            <ListHeader>Head Office</ListHeader>
            <Flex alignItems={'center'}>
              <FaLocationArrow /> &nbsp;California, United States
            </Flex>
          </Stack>
        </SimpleGrid>
        <Flex
          justifyContent={'center'}
          borderTop={'1px'}
          paddingTop={'10px'}
          marginTop={'10px'}
        >
          <small>
            COPYRIGHT &copy; 2025 CODISE-IT - ALL RIGHTS RESERVED. &nbsp;{' '}
            <a href={PdfFile} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{' '}
            &nbsp; | &nbsp; POWERED BY CODISE
          </small>
        </Flex>
      </Stack>
    </Box>
    </Box>
  );
};

export default Footer;
