import React from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Image,
  Stack,
  Text,
  Divider,
} from '@chakra-ui/react';
import SectionTop from '../../Components/SectionTop';
import {
  FcAssistant,
} from 'react-icons/fc';
import { Link } from 'react-router-dom';

// Images
import BackImage from '../../Assets/images/serviceimage.webp';
import Img11 from "../../Assets/images/Services/01 Network setup and Management/Networl Setup and Managment 1.jpg"
import Img12 from "../../Assets/images/Services/01 Network setup and Management/Network Setup 2.jpg"
import Img21 from "../../Assets/images/Services/02 Help Desk/1.jpg"
import Img22 from "../../Assets/images/Services/02 Help Desk/2.png"
import Img31 from "../../Assets/images/Services/03 Mobile Device and Management/1.jpg"
import Img32 from "../../Assets/images/Services/03 Mobile Device and Management/2.jpg"
import Img41 from "../../Assets/images/Services/04 IT infrasturcture and Monitoring/1.jpg"
import Img42 from "../../Assets/images/Services/04 IT infrasturcture and Monitoring/2.jpeg"
import Img51 from "../../Assets/images/Services/05 Software Development and Integration/1.jpg"
import Img52 from "../../Assets/images/Services/05 Software Development and Integration/2.jpg"
import Img61 from "../../Assets/images/Services/06 IT Consultation and Project Managment/1.jpg"
import Img62 from "../../Assets/images/Services/06 IT Consultation and Project Managment/2.jpg"
import Img71 from "../../Assets/images/Services/07 Network Security and Cybersecurity/1.jpg"
import Img72 from "../../Assets/images/Services/07 Network Security and Cybersecurity/2.jpg"
import Img81 from "../../Assets/images/Services/08 Server Administration/1.jpg"
import Img82 from "../../Assets/images/Services/08 Server Administration/2.jpg"
import Img91 from "../../Assets/images/Services/09 Data Backup and Recovery/1.jpg"
import Img92 from "../../Assets/images/Services/09 Data Backup and Recovery/2.jpg"
import Img101 from "../../Assets/images/Services/10 Iot/1.jpg"
import Img102 from "../../Assets/images/Services/10 Iot/2.jpg"

const ServiceCard = ({ heading, description, icon, href, image1, image2 }) => {

  return (
    <Link to={'/services/'+heading}>
    <Box
      maxW={{ base: 'full', md: '296px' }}
      color={'#000'}
      boxShadow="lg"
      bg="transparent"
      bgGradient={'linear(to-r, #fff,  #F7FAFF, #F7FAFF, rgb(205, 227, 255))'}
      w={'full'}
      borderWidth="1px"
      borderColor={'rgba(255,255,255,10%)'}
      borderRadius={'20px'}
      overflow="hidden"
      p={4} 
    >
      <Stack align={'start'} justifyContent={'space-between'} height={'100%'}>
        <Flex w={'100%'} alignItems={'center'} justifyContent={'flex-end'}>
          {
            <Image
              src={image1}
              alt={heading}
              height={'100px'}
              borderRadius="20px"
            />
          }
        </Flex>

        <Box mt={4} textAlign={'start'} width={'100%'}>
          <Flex
            width={'100%'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Heading size="md" display={'flex'} textAlign={'start'}>
              {heading}
            </Heading>
          </Flex>
          <Text mt={2} p={2} fontSize={'sm'} textAlign={'left'}>
            {description}
          </Text>
        </Box>
      </Stack>
    </Box>
    </Link>
  );
};

const Services = () => {
  
  const serviceData = [
    {
      id: 1,
      serviceImg1: Img11,
      serviceImg2: Img12,
      serviceName: 'Network Setup and Management',
      serviceInfo: 'Installation, configuration, and maintenance of network infrastructure. LAN/WAN setup and network management. Firewall implementation and Security Services. Network monitoring and troubleshooting.',
    },
    {
      id: 2,
      serviceImg1: Img21,
      serviceImg2: Img22,
      serviceName: 'Help Desk and Technical Support',
      serviceInfo: 'Remote and on-site technical assistance. Troubleshooting hardware and software issues. User support and training. Incident management and resolution.',

    },
    {
      id: 3,
      serviceImg1: Img31,
      serviceImg2: Img32,
      serviceName: 'Mobile Device Management (MDM)',
      serviceInfo: 'Mobile device configuration and provisioning. Application management and deployment. Security and policy enforcement. Remote device tracking and wiping.',
    },
    {
      id: 4,
      serviceImg1: Img41,
      serviceImg2: Img42,
      serviceName: 'IT Infrastructure Monitoring',
      serviceInfo: 'Proactive monitoring of servers, networks, and systems. Performance and availability monitoring. Alerting and notification services. Capacity planning and optimization.',
    },
    {
      id: 5,
      serviceImg1: Img51,
      serviceImg2: Img52,
      serviceName: 'Software Development and Integration',
      serviceInfo: 'Custom software development based on client requirements. Application integration and middleware solutions. Legacy system modernization and migrationQuality assurance and testing services.',
    },
    {
      id: 6,
      serviceImg1: Img61,
      serviceImg2: Img62,
      serviceName: 'IT Consulting and Project Management',
      serviceInfo: 'IT strategy development and planning. Technology assessment and recommendations. Project scoping, implementation, and management. IT budgeting and resource allocation.',
    },
    {
      id: 7,
      serviceImg1: Img71,
      serviceImg2: Img72,
      serviceName: 'Network Security and Cybersecurity',
      serviceInfo: 'Vulnerability assessments and penetration testing. Firewall and antivirus setup and management. Intrusion detection and prevention systems. Security audits and policy enforcement.',
    },
    {
      id: 8,
      serviceImg1: Img81,
      serviceImg2: Img82,
      serviceName: 'Server Administration',
      serviceInfo: 'Server setup, configuration, and maintenance. User management and access control. Performance monitoring and optimization. Backup and disaster recovery solutions.',
    },
    {
      id: 9,
      serviceImg1: Img91,
      serviceImg2: Img92,
      serviceName: 'Data Backup and Recovery',
      serviceInfo: 'Regular data backup solutions. Data recovery services in case of hardware failure or data loss. Implementation of backup strategies and disaster recovery plans. Off-site data storage options.',
    },
    {
      id: 10,
      serviceImg1: Img101,
      serviceImg2: Img102,
      serviceName: 'Internet of Things (IoT) Integration',
      serviceInfo: 'Designing and implementing IoT solutions for businesses. Connecting and integrating IoT devices and sensors. Developing custom applications to analyze and utilize IoT data. Ensuring security and scalability of IoT infrastructure.',
    },
  ];
  return (
    <Box>
      <SectionTop
        title="Codise Service"
        desc="We blend expertise with a customer-centric approach to deliver tailored IT services that drive growth, enhance efficiency, and ensure security. Explore our services 
      transform your IT landscape."
      />

      <Box mt={10} mb={20} align={'center'} maxW="1200px" mx="auto">
        <Box>
          <Flex flexWrap="wrap" gridGap={12} justify="center">
            {serviceData.map(serviceItem => {
              return (
                <ServiceCard
                  key={serviceItem.id}
                  heading={serviceItem.serviceName}
                  icon={<Icon as={FcAssistant} w={10} h={10} />}
                  description={serviceItem.serviceInfo}
                  image1={serviceItem.serviceImg1}
                  image2={serviceItem.serviceImg2}
                  href={'#'}
                />
              );
            })}

            <Box
              flexBasis={{ base: '100%', md: 'calc(50% + 30px)' }} // Spans two columns with gap
              maxW={{ base: 'full', md: 'calc(50% + 30px)' }} // Ensures it covers two card spaces + gap
              color={'#000'}
              boxShadow="lg"
              bg="transparent"
              bgGradient={
                'linear(to-r, #fff, #F7FAFF, #F7FAFF, rgb(205, 227, 255))'
              }
              w={'full'}
              borderWidth="1px"
              borderColor={'rgba(255,255,255,10%)'}
              borderRadius={'20px'}
              overflow="hidden"
              p={4}
              textAlign="center"
            >
              <Flex
                direction={{ base: 'column', md: 'row' }} // Stack vertically on small screens, horizontally on medium and larger
                align="center"
                textAlign="left"
                mb={8}
                gap={5}
                p={4} // Add padding for better spacing on mobile
              >
                <Heading fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
                  Are you ready to collaborate with us? Contact us now!!
                </Heading>
                <Link to="/contact">
                <Button
                  mt={{ base: 4, md: 0 }} // Add margin-top on small screens, remove on larger
                  colorScheme="red"
                  bg="red.500"
                  size="lg"
                  _hover={{ bg: 'red.600' }}
                >
                  Get started
                </Button>
                </Link>
              </Flex>
              <Divider borderColor="gray.300" mb={5} />

              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                <Box
                  p={6}
                  bg="white"
                  borderRadius="lg"
                  shadow="md"
                  textAlign="center"
                >
                  <Text fontSize="lg" fontWeight="bold">
                    Mail us
                  </Text>
                  <Text mt={2} color="gray.600">
                    Tell us about your vision to get started.
                  </Text>
                  <Link
                    href="mailto:info@codise-it.com"
                    fontWeight="bold"
                    color="blue.500"
                    mt={2}
                  >
                    info@codise-it.com
                  </Link>
                </Box>

                <Box
                  p={6}
                  bg="white"
                  borderRadius="lg"
                  shadow="md"
                  textAlign="center"
                >
                  <Text fontSize="lg" fontWeight="bold">
                    Contact us
                  </Text>
                  <Text mt={2} color="gray.600">
                    Let’s discuss your needs & details. Speak soon!
                  </Text>
                  <Link href="https://wa.me/15629779228?text=I'm%20inquiring%20about%20the%20It%20Service" fontWeight="bold" color="blue.500" mt={2}>
                    Let’s talk with us
                  </Link>
                </Box>
              </SimpleGrid>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box
        position="relative"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundImage={BackImage}
      >
        <Container size="container.lg" height="600px" position="relative">
          <Stack
            spacing={6}
            w={'full'}
            maxW={'lg'}
            position="absolute"
            top="50%"
            transform="translate(0, -50%)"
          ></Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default Services;
