import React from 'react';
import {
  Stack,
  Button,
  Heading,
  Input,
  Text,
  Box,
  Flex,
  Textarea,
} from '@chakra-ui/react';
import {useColorModeValue } from '@chakra-ui/react';
import axios from "axios"

const ContactForm = () => {
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  // const [loading, setLoading] = React.useState(false);
  const [contactnumber, setContactnumber] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);
    const data = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      contactnumber: contactnumber,
      message: message,
      };
      console.log(data);
      try {
        await axios.post('http://localhost:8000/send-email', data);
        setSuccess(true)
        alert("Mail Sent")
        // console.log('Response:', response.data);
      } catch (error) {
        setError(true);
        console.error('Error:', error.response ? error.response.data : error.message);
      }

      // setLoading(false);
  }

  return (
    <form action="#" method="get">
    <Flex
      align={'center'}
      justify={'center'}
      //   py={12}
    >
      <Stack w={'100%'} rounded={'xl'} p={5} align={'start'}>
        <Stack align={'center'} spacing={2} mb={'10px'}>
          <Heading
            // textTransform={'uppercase'}
            fontSize={'xl'}
            color={useColorModeValue('gray.800', 'gray.200')}
          >
            Drop us a line
          </Heading>
          {/* <Text fontSize={'lg'} color={'gray.500'}>
          Drop us a line
          </Text> */}
        </Stack>
        <hr />
        <Stack spacing={4} direction={{ base: 'column', md: 'row' }} w={'full'}>
          <Input
            type={'text'}
            placeholder={'First Name'}
            name='firstname'
            id="firstname"
            value = {firstname}
            onChange={(e) => setFirstname(e.target.value)}
            color={useColorModeValue('gray.800', 'gray.200')}
            bg={useColorModeValue('gray.100', 'gray.600')}
            // rounded={'full'}
            border={0}
            _focus={{
              bg: useColorModeValue('gray.200', 'gray.800'),
              outline: 'none',
            }}
          />
          <Input
            type={'text'}
            placeholder={'Last Name'}
            name='lastname'
            id="lastname"
            value = {lastname}
            onChange={(e) => setLastname(e.target.value)}
            color={useColorModeValue('gray.800', 'gray.200')}
            bg={useColorModeValue('gray.100', 'gray.600')}
            // rounded={'full'}
            border={0}
            _focus={{
              bg: useColorModeValue('gray.200', 'gray.800'),
              outline: 'none',
            }}
          />
        </Stack>
        <Stack spacing={4} direction={{ base: 'column', md: 'row' }} w={'full'}>
          <Input
          required
            type={'text'}
            placeholder={'Email'}
            name='email'
            id="email"
            value = {email}
            onChange={(e) => setEmail(e.target.value)}
            color={useColorModeValue('gray.800', 'gray.200')}
            bg={useColorModeValue('gray.100', 'gray.600')}
            // rounded={'full'}
            border={0}
            _focus={{
              bg: useColorModeValue('gray.200', 'gray.800'),
              outline: 'none',
            }}
          />
        </Stack>
        <Stack spacing={4} direction={{ base: 'column', md: 'row' }} w={'full'}>
          <Input
            required
            type={'number'}
            placeholder={'Contact Number'}
            name='contactnumber'
            id="contactnumber"
            value = {contactnumber}
            onChange={(e) => setContactnumber(e.target.value)}
            color={useColorModeValue('gray.800', 'gray.200')}
            bg={useColorModeValue('gray.100', 'gray.600')}
            // rounded={'full'}
            border={0}
            _focus={{
              bg: useColorModeValue('gray.200', 'gray.800'),
              outline: 'none',
            }}
          />
        </Stack>
        <Stack spacing={4} direction={{ base: 'column', md: 'row' }} w={'full'}>
          <Textarea
            type={'text'}
            placeholder={'Let us know what you need.'}
            name='message'
            id="message"
            value = {message}
            onChange={(e) => setMessage(e.target.value)}
            color={useColorModeValue('gray.800', 'gray.200')}
            bg={useColorModeValue('gray.100', 'gray.600')}
            // rounded={'10%'}
            border={0}
            _focus={{
              bg: useColorModeValue('gray.200', 'gray.800'),
              outline: 'none',
            }}
          />
        </Stack>

        <Button
          w={'full'}
          bg={'blue.400'}
          rounded={'full'}
          color={'white'}
          flex={'1 0 auto'}
          _hover={{ bg: 'blue.500' }}
          _focus={{ bg: 'blue.500' }}
          type="submit"
                        value="Submit"
                        onClick={(e) => handleSubmit(e)}
        >
          Submit
        </Button>

        <Box textAlign={"center"} w={"100%"}>
        {
          (success)?(<Text color={'green.500'}>Mail Sent! We will contact you soon!! </Text>
          ):( (error)?(<Text color={'red.500'}>Please Fill all details correctly!</Text>):(<></>)
          )
        }
        </Box>
      </Stack>

    </Flex>
    </form>
  );
};

export default ContactForm;
