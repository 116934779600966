import {
  Box,
  Container,
  Stack,
  Text,
  VStack,
  HStack,
  Icon,
  Heading,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react';
import SectionTop from "../../../Components/SectionTop"
import { CheckIcon } from '@chakra-ui/icons';
import React from 'react';
import { useParams } from 'react-router-dom';


import Img12 from "../../../Assets/images/Services/01 Network setup and Management/Network Setup 2.jpg"
import Img22 from "../../../Assets/images/Services/02 Help Desk/2.png"
import Img32 from "../../../Assets/images/Services/03 Mobile Device and Management/2.jpg"
import Img42 from "../../../Assets/images/Services/04 IT infrasturcture and Monitoring/2.jpeg"
import Img52 from "../../../Assets/images/Services/05 Software Development and Integration/2.jpg"
import Img62 from "../../../Assets/images/Services/06 IT Consultation and Project Managment/2.jpg"
import Img72 from "../../../Assets/images/Services/07 Network Security and Cybersecurity/2.jpg"
import Img82 from "../../../Assets/images/Services/08 Server Administration/2.jpg"
import Img92 from "../../../Assets/images/Services/09 Data Backup and Recovery/2.jpg"
import Img102 from "../../../Assets/images/Services/10 Iot/2.jpg"
import AboutImg from '../../About/AboutImg.js';


const Service = () => {
  const params = useParams();

  const serviceData = [
    {
      id: 1,
      serviceName: 'Network Setup and Management',
      serviceImg2: Img12,
      quoteLine: 'Your Path to Seamless Connectivity.',
      mainPara:
        "In the digital era, a robust and well-managed network is the lifeblood of any organization. It's the conduit through which data flows, enabling communication, collaboration, and productivity. At CODISE-IT, we specialize in network setup and management services that empower your business with efficient and secure connectivity.",
      mainLine: 'Why Choose Us for Network Setup and Management??',
      mainpoints: [
        {
          pointid: 1,
          pointTitle: 'Customized Solutions:',
          pointLine:
            "Recognizing that each business has unique networking needs, we tailor our solutions to meet your specific requirements, whether you're a small business or a large enterprise.",
        },
        {
          pointid: 2,
          pointTitle: 'End-to-End Services:',
          pointLine:
            'From network design and setup to ongoing monitoring and maintenance, we offer comprehensive network services that cover the entire network lifecycle.',
        },
        {
          pointid: 3,
          pointTitle: 'Proactive Maintenance:',
          pointLine:
            'We believe that prevention is key. Our proactive network maintenance helps identify and resolve issues before they disrupt your operations.',
        },
      ],
      serviceInfoMain: 'Our Network Setup and Management Services:',
      serviceInfo: [
        {
          topicid: 1,
          topic: 'Network Design and Architecture:',
          topicPoints: [
            "Thorough assessment of your organization's networking needs.",
            'Customized network design and architecture planning.',
            'Scalability planning to accommodate future growth.',
          ],
        },
        {
          topicid: 2,
          topic: 'Network Setup and Configuration:',
          topicPoints: [
            'Expert installation and configuration of networking hardware.',
            'Ensuring seamless connectivity and network optimization.',
            'Firewall setup and security measures to protect your network.',
          ],
        },
        {
          topicid: 3,
          topic: 'Network Monitoring and Maintenance:',
          topicPoints: [
            'Network Monitoring and Maintenance:',
            'Real-time network monitoring for performance and security.',
            'Regular maintenance to keep your network running smoothly.',
          ],
        },
        {
          topicid: 4,
          topic: 'Wireless Network Solutions:',
          topicPoints: [
            'Rapid issue identification and resolution.',
            'Setup and optimization of wireless networks.',
            'Ensuring coverage and performance in all areas of your business.',
          ],
        },
        {
          topicid: 5,
          topic: 'Network Security and Compliance:',
          topicPoints: [
            'Secure wireless access points and user authentication.',
            'Robust security measures, including intrusion detection and prevention.',
            'Compliance with industry-specific regulations (e.g., HIPAA, GDPR).',
          ],
        },
        {
          topicid: 6,
          topic: 'Disaster Recovery and Redundancy:',
          topicPoints: [
            'Regular security audits and vulnerability assessments.',
            'Developing disaster recovery plans for network outages.',
            'Implementing redundancy and failover solutions for high availability.',
            'Minimizing downtime and data loss in case of network disruptions.',
          ],
        },
      ],
    },
    {
      id: 10,
      serviceImg2: Img102,
      serviceName: 'Internet of Things (IoT) Integration',
      quoteLine: "From Devices to Data, We've Got You Covered.",
      mainPara:
        "At CODISE, we are at the forefront of the Internet of Things (IoT) revolution, offering cutting-edge IoT services that propel businesses into the digital age. Our dedicated team of experts specializes in designing, implementing, and managing IoT solutions that seamlessly connect devices, sensors, and systems, transforming data into actionable insights. With a focus on innovation and efficiency, we empower our clients to harness the full potential of IoT technology to enhance operations, optimize resource utilization, and drive informed decision-making. Whether it's optimizing manufacturing processes, improving supply chain visibility, or enhancing customer experiences, our IoT services are tailored to meet the unique needs of each client, ensuring they stay competitive in today's rapidly evolving business landscape. Trust CODISE as your IoT partner, and together, we'll shape a smarter and more connected future.",
      mainLine: 'Why Choose Us for IOT related Sercices??',
      mainpoints: [
        {
          pointid: 1,
          pointTitle: 'Comprehensive IoT Solutions:',
          pointLine:
            'We offer end-to-end IoT solutions, covering everything from device connectivity to data analytics and insights. Our holistic approach ensures that your IoT ecosystem is robust, scalable, and fully aligned with your business objectives.',
        },
        {
          pointid: 2,
          pointTitle: 'Industry-Specific Expertise:',
          pointLine:
            "With years of experience, we understand that each industry has its unique requirements. We leverage industry-specific knowledge to tailor IoT solutions that meet your specific needs, whether you're in manufacturing, healthcare, logistics, or any other sector.",
        },
        {
          pointid: 3,
          pointTitle: 'Cutting-Edge Technology:',
          pointLine:
            'We stay ahead of the curve by working with the latest IoT technologies and platforms. Our team of experts is continuously updating their skills to deliver innovative solutions that leverage the most advanced tools available.',
        },
        {
          pointid: 4,
          pointTitle: 'Data-Driven Insights',
          pointLine:
            'We understand that data is at the heart of IoT. Our solutions not only connect devices but also provide actionable insights. We help you make informed decisions by turning data into valuable information.',
        },
      ],
      serviceInfoMain: 'Our IOT Related Services:',
      serviceInfo: [
        {
          topicid: 1,
          topic: 'IoT Strategy and Consulting:',
          topicPoints: [
            'Our seasoned IoT consultants work closely with your organization to define your IoT strategy.',
            'We help identify opportunities, set clear objectives, and create a roadmap that aligns with your business goals.',
            'With CODISE, your IoT journey begins with a solid plan.',
          ],
        },
        {
          topicid: 2,
          topic: 'Device Connectivity and Integration:',
          topicPoints: [
            'Effortlessly connect and integrate IoT devices into your existing infrastructure.',
            'We specialize in designing secure, reliable, and scalable connectivity solutions that enable real-time data exchange between devices, cloud platforms, and applications.',
          ],
        },
        {
          topicid: 3,
          topic: 'Data Management and Analytics:',
          topicPoints: [
            'Unlock the full potential of your IoT-generated data with our advanced data management and analytics services.',
            'We help you collect, store, process, and analyze vast datasets to derive actionable insights that fuel data-driven decision-making.',
          ],
        },
        {
          topicid: 4,
          topic: 'IoT Application Development:',
          topicPoints: [
            'Leverage our expertise in IoT application development to create customized solutions that enhance your operational efficiency and customer experiences.',
            'From mobile apps to web interfaces, we craft intuitive applications that interact seamlessly with your IoT ecosystem.',
          ],
        },
        {
          topicid: 5,
          topic: 'Security and Compliance:',
          topicPoints: [
            "Whether you're in manufacturing, healthcare, logistics, or any other industry, we provide tailored IoT solutions that address your unique challenges and opportunities.",
            'Our industry expertise allows us to deliver solutions that make a real impact.',
          ],
        },
        {
          topicid: 6,
          topic: 'Industry-Specific Solutions:',
          topicPoints: [
            'Regular security audits and vulnerability assessments.',
            'Developing disaster recovery plans for network outages.',
            'Implementing redundancy and failover solutions for high availability.',
            'Minimizing downtime and data loss in case of network disruptions.',
          ],
        },
      ],
    },
    {
      id: 3,
      serviceImg2: Img92,
      serviceName: 'Data Backup and Recovery',

      quoteLine:
        'Secure Data Backup and Swift Recovery: Your Safety Net in a Digital World',

      mainPara:
        "In today's data-driven world, protecting your valuable digital assets is paramount. Unexpected data loss can disrupt your business and lead to significant downtime. At CODISE-IT, we offer robust data backup and recovery services to ensure that your data is secure and can be quickly restored in times of need.",

      mainLine: 'Why Choose Us for Data Backup and Recovery',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Proven Expertise:',

          pointLine:
            'Our team of data backup and recovery experts has a track record of successfully protecting and recovering data for businesses of all sizes. We continuously stay abreast of the latest technologies and best practices.',
        },

        {
          pointid: 2,

          pointTitle: 'Tailored Solutions:',

          pointLine:
            'We understand that data needs vary widely among businesses. Our services are customized to meet your specific data requirements, whether you are a small startup or a large enterprise.',
        },

        {
          pointid: 3,

          pointTitle: 'Comprehensive Services:',

          pointLine:
            'From data backup strategy development to regular monitoring and swift recovery, we provide end-to-end data backup and recovery solutions designed to ensure data availability and minimize downtime.',
        },

        {
          pointid: 4,

          pointTitle: 'Rapid Response:',

          pointLine:
            "In the event of data loss or system failure, we're ready to spring into action. Our rapid response team is available 24/7 to initiate recovery procedures immediately.",
        },
      ],
      serviceInfoMain: 'Our Data Backup and Recovery Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'Data Backup Strategy:',

          topicPoints: [
            'Assess your data backup needs and business continuity requirements.',

            'Develop a tailored data backup and retention strategy.',

            'Determine the most suitable backup methods (local, cloud, hybrid).',
          ],
        },

        {
          topicid: 2,

          topic: 'Data Backup and Replication:',

          topicPoints: [
            'Set up automated data backups with redundancy for maximum reliability.',

            'Continuous data replication for real-time backup.',

            'Encrypted data storage to safeguard against data breaches.',
          ],
        },

        {
          topicid: 3,

          topic: 'Disaster Recovery Planning:',

          topicPoints: [
            'Create a comprehensive disaster recovery plan (DRP).',

            'Conduct regular DRP testing and refinement.',

            'Ensure a documented, step-by-step recovery process.',
          ],
        },

        {
          topicid: 4,

          topic: 'Data Recovery Services:',

          topicPoints: [
            'Rapid data recovery in case of data loss, corruption, or system failure.',

            'Data restoration to minimize downtime and data loss.',

            'Recovery of data from various media and storage systems.',
          ],
        },

        {
          topicid: 5,

          topic: 'Regular Monitoring and Maintenance:',

          topicPoints: [
            'Ongoing monitoring of data backup systems.',

            'Routine data backup and recovery testing.',

            'Periodic system updates and upgrades.',
          ],
        },

        {
          topicid: 6,

          topic: 'Security and Compliance:',

          topicPoints: [
            'Implement robust security measures to protect stored data.',

            'Ensure compliance with industry-specific data regulations.',

            'Encryption and access control to safeguard sensitive data.',
          ],
        },
      ],
    },
    {
      id: 3,
      serviceImg2: Img22,
      serviceName: 'Help Desk and Technical Support',

      quoteLine:
        'Reliable Help Desk and Technical Support: Your Lifeline to Smooth Operations',

      mainPara:
        "In today's technology-driven landscape, businesses rely on seamless IT operations to stay competitive. However, technical issues and IT challenges can arise unexpectedly, disrupting productivity and causing frustration. At CODISE-IT, we specialize in providing top-notch Help Desk and Technical Support services designed to keep your systems running smoothly.",

      mainLine: 'Why Choose Us for Help Desk and Technical Support:',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Experience You Can Trust:',

          pointLine:
            'Our team of skilled technicians and support experts has a proven track record of swiftly resolving technical issues. We continuously update our knowledge to keep pace with the ever-evolving tech landscape.',
        },

        {
          pointid: 2,

          pointTitle: 'Customized Solutions: ',

          pointLine:
            "We understand that each organization has unique IT needs. Our services are tailored to fit your specific requirements, whether you're a small business or a large enterprise.",
        },

        {
          pointid: 3,

          pointTitle: 'End-to-End Support:',

          pointLine:
            ' From initial issue reporting to problem resolution and ongoing maintenance, we offer comprehensive support services that encompass the entire IT support lifecycle.',
        },

        {
          pointid: 4,

          pointTitle: 'Swift Response:',

          pointLine:
            'When IT issues arise, time is of the essence. Our commitment to a rapid response ensures minimal downtime and maximum productivity.',
        },
      ],
      serviceInfoMain: 'Our Help Desk and Technical Support Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: '24/7 Help Desk Support:',

          topicPoints: [
            'Around-the-clock availability to assist with technical issues.',

            'Expert technicians ready to provide remote assistance.',

            'Timely response to inquiries, ensuring uninterrupted workflow.',
          ],
        },

        {
          topicid: 2,

          topic: 'Troubleshooting and Issue Resolution:',

          topicPoints: [
            'Swift identification and resolution of IT problems.',

            'In-depth diagnosis to prevent recurring issues.',

            'Proactive measures to address root causes.',
          ],
        },

        {
          topicid: 3,

          topic: 'User Support and Training:',

          topicPoints: [
            'Assistance and guidance for end-users in navigating IT systems.',

            'Training programs to enhance IT literacy among employees.',

            'User-friendly documentation and resources.',
          ],
        },

        {
          topicid: 4,

          topic: 'Incident Management:',

          topicPoints: [
            'Streamlined incident reporting and tracking.',

            'Prioritization of critical issues for rapid resolution.',

            'Thorough documentation of incidents for future reference.',
          ],
        },

        {
          topicid: 5,

          topic: 'Software and Hardware Support:',

          topicPoints: [
            'Expertise in troubleshooting software glitches and hardware malfunctions.',

            'Assistance with software installation, updates, and patch management.',

            'Recommendations for hardware upgrades and replacements.',
          ],
        },

        {
          topicid: 6,

          topic: 'Performance Optimization:',

          topicPoints: [
            'Regular performance assessments to identify bottlenecks.',

            'Implementing solutions for system and network optimization.',

            'Enhancing overall IT infrastructure performance.',
          ],
        },
      ],
    },
    {
      id: 4,
      serviceImg2: Img32,
      serviceName: 'Mobile Device Management (MDM)',

      quoteLine:
        'Seamless Mobile Device Management (MDM): Your Key to Secure and Efficient Mobility',

      mainPara:
        'In the age of mobility, smartphones, tablets, and other mobile devices have become indispensable tools for businesses. However, managing these devices while ensuring data security and compliance can be challenging. At CODISE-IT, we offer comprehensive Mobile Device Management (MDM) services that empower organizations to embrace mobility without compromising on security.',

      mainLine: 'Why Choose Us for Mobile Device Management (MDM):',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Expertise You Can Count On:',

          pointLine:
            'Our team of MDM specialists possesses extensive experience in managing diverse mobile device environments. We keep abreast of the latest mobile technologies and security protocols.',
        },

        {
          pointid: 2,

          pointTitle: 'End-to-End MDM Services: ',

          pointLine:
            "Recognizing that every organization has unique mobile device management needs, our solutions are tailored to meet your specific requirements, whether you're a small business or a large enterprise.",
        },

        {
          pointid: 3,

          pointTitle: 'End-to-End Support:',

          pointLine:
            'From initial setup and configuration to ongoing monitoring and support, we offer comprehensive MDM services that cover the entire lifecycle of mobile devices in your organization.',
        },

        {
          pointid: 4,

          pointTitle: 'Data Security:',

          pointLine:
            'We prioritize data security and implement robust measures to safeguard your sensitive information on mobile devices, ensuring compliance with industry regulations.',
        },
      ],
      serviceInfoMain: 'Our Mobile Device Management (MDM) Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'Device Enrollment and Configuration:',

          topicPoints: [
            'Streamlined device enrollment for easy onboarding.',

            "Customized device configurations based on your organization's policies.",

            'Installation and updates of business apps and profiles.',
          ],
        },

        {
          topicid: 2,

          topic: 'Security and Compliance:',

          topicPoints: [
            'Implementation of strong security measures, including encryption and remote data wipe capabilities.',

            'Regular compliance assessments to ensure adherence to industry-specific regulations.',

            'Monitoring for device compliance and security policy enforcement.',
          ],
        },

        {
          topicid: 3,

          topic: 'App Management:',

          topicPoints: [
            'Centralized app deployment and management.',

            'Control over app distribution, updates, and removal.',

            'App whitelisting and blacklisting to maintain security and productivity.',
          ],
        },

        {
          topicid: 4,

          topic: 'Remote Support and Troubleshooting:',

          topicPoints: [
            'Real-time remote support to resolve device issues.',

            'Troubleshooting and problem resolution for mobile devices.',

            'Efficient issue tracking and resolution.',
          ],
        },

        {
          topicid: 5,

          topic: 'User Training and Education:',

          topicPoints: [
            'Training programs to educate users on MDM best practices.',

            'User-friendly resources and documentation for self-help.',

            'Promoting mobile security awareness among employees.',
          ],
        },

        {
          topicid: 6,

          topic: 'Performance Optimization:',

          topicPoints: [
            'Continuous monitoring for device performance and battery life.',

            'Implementing optimizations to enhance device speed and responsiveness.',

            'Regular assessments to ensure mobile device performance aligns with business needs.',
          ],
        },
      ],
    },

    {
      id: 5,
      serviceImg2: Img42,
      serviceName: 'IT Infrastructure Monitoring',

      quoteLine:
        'Proactive IT Infrastructure Monitoring: Keeping Your Business Ahead of the Curve',

      mainPara:
        "In today's digital age, a reliable and efficient IT infrastructure is the lifeblood of any organization. However, ensuring that your IT systems are running smoothly and securely can be a daunting task. At CODISE-IT, we specialize in IT Infrastructure Monitoring services designed to provide you with real-time insights, proactively identify issues, and keep your operations running at peak performance.",

      mainLine: 'Why Choose Us for IT Infrastructure Monitoring:',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Experience and Expertise:',

          pointLine:
            'Our team of IT monitoring experts brings years of experience in managing diverse IT environments. We stay up-to-date with the latest technologies and best practices.',
        },

        {
          pointid: 2,

          pointTitle: 'Customized Solutions:',

          pointLine:
            "We recognize that every organization has unique IT monitoring needs. Our solutions are tailored to meet your specific requirements, whether you're a small business or a large enterprise.",
        },

        {
          pointid: 3,

          pointTitle: 'Comprehensive Services:',

          pointLine:
            'From initial setup and configuration to ongoing monitoring and reporting, we offer end-to-end IT infrastructure monitoring services that cover the entire IT landscape.',
        },

        {
          pointid: 4,

          pointTitle: 'Proactive Approach:',

          pointLine:
            'We believe in proactive monitoring to identify and address issues before they impact your business. Our goal is to keep your systems running smoothly and minimize downtime.',
        },
      ],
      serviceInfoMain: 'Our IT Infrastructure Monitoring Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'Network Monitoring:',

          topicPoints: [
            'Real-time monitoring of network devices, bandwidth, and performance.',

            'Proactive identification of network bottlenecks and issues.',

            'Historical data analysis for trend identification and optimization.',
          ],
        },

        {
          topicid: 2,

          topic: 'Server Monitoring:',

          topicPoints: [
            'Continuous monitoring of server health, resource utilization, and performance.',

            'Alerts for potential server failures or performance degradation.',

            'Optimization recommendations for server infrastructure.',
          ],
        },

        {
          topicid: 3,

          topic: 'Application Monitoring:',

          topicPoints: [
            'Comprehensive monitoring of critical applications and services.',

            'Performance tracking and alerting for application issues.',

            'Application availability and response time monitoring.',
          ],
        },

        {
          topicid: 4,

          topic: 'Security Monitoring:',

          topicPoints: [
            'Detection of security threats and suspicious activities in real-time.',

            'Compliance monitoring to ensure adherence to security standards.',

            'Log analysis and incident response for security breaches.',
          ],
        },

        {
          topicid: 5,

          topic: 'Cloud Monitoring:',

          topicPoints: [
            'Monitoring of cloud resources and services in hybrid and multi-cloud environments.',

            'Resource utilization tracking and cost optimization.',

            'Automated scaling based on real-time data.',
          ],
        },

        {
          topicid: 6,

          topic: 'Reporting and Analytics:',

          topicPoints: [
            'Customized reports and dashboards to track key performance metrics.',

            'Data analysis for insights into infrastructure trends and opportunities.',

            'Regular reporting to stakeholders for transparency and decision-making.',
          ],
        },
      ],
    },

    {
      id: 5,
      serviceImg2: Img52,
      serviceName: 'Software Development and Integration',

      quoteLine:
        'Empower Your Business with Seamless Software Development and Integration',

      mainPara:
        'In the digital age, software is at the heart of business operations. Custom software solutions tailored to your specific needs can drive efficiency, innovation, and growth. At CODISE-IT, we specialize in Software Development and Integration services that empower organizations to harness the full potential of technology.',

      mainLine: 'Why Choose Us for Software Development and Integration:',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Proven Expertise:',

          pointLine:
            'Our team of software developers and integration specialists boasts a track record of delivering high-quality solutions. We stay updated with the latest programming languages, frameworks, and integration techniques.',
        },

        {
          pointid: 2,

          pointTitle: 'Tailored Solutions:',

          pointLine:
            'We understand that every business has unique software requirements. Our solutions are customized to meet your specific needs, ensuring they align with your goals and challenges.',
        },

        {
          pointid: 3,

          pointTitle: 'End-to-End Services:',

          pointLine:
            'From initial concept and development to seamless integration with existing systems, we offer comprehensive services that cover the entire software development and integration lifecycle.',
        },

        {
          pointid: 4,

          pointTitle: 'Commitment to Excellence:',

          pointLine:
            'We are committed to delivering software solutions that not only meet but exceed your expectations. Quality, performance, and user experience are at the forefront of our development process.',
        },
      ],
      serviceInfoMain: 'Our IT Infrastructure Monitoring Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'Custom Software Development:',

          topicPoints: [
            'Collaborative software design and architecture planning.',

            'Development of custom software applications tailored to your business processes.',

            'User-centric design for intuitive and efficient user experiences.',
          ],
        },

        {
          topicid: 2,

          topic: 'Legacy System Integration:',

          topicPoints: [
            'Seamlessly integrate new software solutions with your existing systems.',

            'Data migration and synchronization to ensure continuity and data integrity',

            'Modernize legacy systems for improved performance and functionality.',
          ],
        },

        {
          topicid: 3,

          topic: 'API Development and Integration:',

          topicPoints: [
            'Develop APIs to enable efficient communication between different software components.',

            'Integrate third-party APIs for added functionality and data access.',

            'Secure and scalable API architecture design.',
          ],
        },

        {
          topicid: 4,

          topic: 'Cloud Application Development:',

          topicPoints: [
            'Design and develop cloud-native applications for scalability and flexibility.',

            'Migrate existing applications to cloud platforms for cost savings and accessibility.',

            'Implement microservices architecture for agility and resilience.',
          ],
        },

        {
          topicid: 5,

          topic: 'Mobile App Development:',

          topicPoints: [
            'Create native and cross-platform mobile applications for iOS and Android.',

            'Mobile app design, development, testing, and deployment.',

            'Integration with backend systems and data sources.',
          ],
        },

        {
          topicid: 6,

          topic: 'Quality Assurance and Testing:',

          topicPoints: [
            'Rigorous testing and quality assurance to identify and rectify issues.',

            'Performance testing, security testing, and user acceptance testing.',

            'Continuous testing throughout the development lifecycle.',
          ],
        },
      ],
    },

    {
      id: 6,
      serviceImg2: Img62,
      serviceName: 'IT Consulting and Project Management',

      quoteLine: 'Your Trusted IT Consulting and Project Management Partner',

      mainPara:
        'In the fast-paced world of technology, businesses require expert guidance to navigate the complexities of IT systems and projects successfully. At CODISE-IT, we are your trusted partner in IT consulting and project management. With our deep industry knowledge and proven methodologies, we ensure that your IT initiatives drive efficiency, innovation, and growth.',

      mainLine: 'Why Choose Us for IT Consulting and Project Management:',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Proven Expertise:',

          pointLine:
            "Our team of seasoned IT consultants and project managers possesses a wealth of industry knowledge and hands-on experience. We've successfully delivered projects across various sectors, from startups to enterprise-level corporations.",
        },

        {
          pointid: 2,

          pointTitle: 'Tailored Solutions:',

          pointLine:
            "We understand that no two businesses are the same. That's why we offer customized IT solutions and project management strategies that align with your unique goals and challenges.",
        },

        {
          pointid: 3,

          pointTitle: 'End-to-End Services:',

          pointLine:
            "From initial consultation to project execution and ongoing support, we offer comprehensive services that cover the entire project lifecycle. We're with you every step of the way.",
        },

        {
          pointid: 4,

          pointTitle: 'Proven Methodologies:',

          pointLine:
            'We follow industry-standard methodologies such as Agile, Waterfall, and Six Sigma to ensure project success. Our approach is flexible and adaptable to meet your specific needs.',
        },
      ],
      serviceInfoMain: 'Our IT Consulting Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'IT Strategy and Planning:',

          topicPoints: [
            'Collaboratively define your IT vision and objectives.',

            'Develop a comprehensive IT strategy aligned with your business goals.',

            'Identify technology solutions to drive innovation and efficiency.',
          ],
        },

        {
          topicid: 2,

          topic: 'Technology Assessment and Recommendations:',

          topicPoints: [
            'Conduct a thorough assessment of your current IT infrastructure.',

            'Provide recommendations for hardware and software upgrades.',

            'Optimize your IT investments for cost-efficiency and performance.',
          ],
        },

        {
          topicid: 3,

          topic: 'IT Project Management:',

          topicPoints: [
            'Expertly manage IT projects from initiation to completion.',

            'Define project scope, objectives, and deliverables.',

            'Ensure projects are completed on time and within budget.',
          ],
        },

        {
          topicid: 4,

          topic: 'Change Management and Training:',

          topicPoints: [
            'Guide your team through technology transitions.',

            'Develop change management strategies to ensure smooth adoption.',

            'Provide training and support to maximize the benefits of new technologies.',
          ],
        },

        {
          topicid: 5,

          topic: 'IT Security and Compliance:',

          topicPoints: [
            'Assess and enhance your IT security measures.',

            'Ensure compliance with industry-specific regulations.',

            'Implement robust cybersecurity solutions to protect your data.',
          ],
        },
      ],
    },

    {
      id: 7,
      serviceImg2: Img72,
      serviceName: 'Network Security and Cybersecurity',

      quoteLine:
        'Protecting Your Digital Fortress: Expert Network Security and Cybersecurity Services',

      mainPara:
        'In an increasingly digital world, the need for robust Network security and cybersecurity measures has never been more critical. At CODISE-IT, we are your dedicated partners in safeguarding your digital assets against evolving threats. With our proven expertise and cutting-edge solutions, we ensure that your business stays resilient and secure in the face of cyber challenges.',

      mainLine: 'Why Choose Us for Network Security and Cybersecurity:',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Proven Expertise:',

          pointLine:
            'Our team of cybersecurity experts brings a wealth of experience and a deep understanding of the latest cyber threats and trends. We stay ahead of the curve to protect your digital infrastructure effectively.',
        },

        {
          pointid: 2,

          pointTitle: 'Tailored Solutions:',

          pointLine:
            ' We recognize that each business is unique. Our cybersecurity solutions are customized to address your specific vulnerabilities, compliance requirements, and risk tolerance.',
        },

        {
          pointid: 3,

          pointTitle: 'Comprehensive Services:',

          pointLine:
            'From risk assessments and security strategy development to threat detection and incident response, we offer end-to-end cybersecurity services to meet all your security needs.',
        },

        {
          pointid: 4,

          pointTitle: 'Continuous Monitoring and Adaptation:',

          pointLine:
            'Cyber threats are dynamic. We provide continuous monitoring and proactive measures to adapt to emerging threats and vulnerabilities.',
        },
      ],
      serviceInfoMain: 'Our Network Security and Cybersecurity Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'Cybersecurity Assessment and Strategy:',

          topicPoints: [
            'Evaluate your existing security posture.',

            'Develop a comprehensive cybersecurity strategy.',

            'Align security with your business objectives.',
          ],
        },

        {
          topicid: 2,

          topic: 'Managed Security Services:',

          topicPoints: [
            '24/7 monitoring of your network and systems.',

            'Rapid threat detection and response.',

            'Ongoing security patch management.',
          ],
        },

        {
          topicid: 3,

          topic: 'Vulnerability Assessment and Penetration Testing:',

          topicPoints: [
            'Identify weaknesses and vulnerabilities.',

            'Conduct penetration testing to assess security measures.',

            'Implement remediation strategies to fortify defenses.',
          ],
        },

        {
          topicid: 4,

          topic: 'Security Awareness Training:',

          topicPoints: [
            'Educate your employees about cybersecurity best practices.',

            'Conduct simulated phishing exercises to test awareness.',

            'Foster a culture of security within your organization',
          ],
        },

        {
          topicid: 5,

          topic: 'Incident Response and Recovery:',

          topicPoints: [
            'Develop incident response plans and procedures.',

            'Provide swift response in the event of a security breach.',

            'Minimize damage and downtime during incidents.',
          ],
        },

        {
          topicid: 6,

          topic: 'Regulatory Compliance:',

          topicPoints: [
            'Ensure compliance with industry-specific regulations (e.g., GDPR, HIPAA).',

            'Conduct compliance assessments and audits.',

            'Implement necessary controls to meet compliance requirements.',
          ],
        },
      ],
    },

    {
      id: 8,
      serviceImg2: Img82,
      serviceName: 'Server Administration',

      quoteLine:
        'Seamless Server Administration: Your Foundation for Uninterrupted Business',

      mainPara:
        'In the digital age, your servers are the backbone of your business operations. Ensuring their smooth operation, security, and scalability is paramount. At CODISE-IT, we are your trusted partner in server administration, delivering tailor-made solutions to keep your servers optimized and your business running seamlessly.',

      mainLine: 'Why Choose Us for Server Administration:',

      mainpoints: [
        {
          pointid: 1,

          pointTitle: 'Proven Expertise:',

          pointLine:
            "Our team of certified server administrators boasts extensive experience in managing diverse server environments. We're committed to staying updated with the latest technologies and best practices.",
        },

        {
          pointid: 2,

          pointTitle: 'Customized Solutions:',

          pointLine:
            ' We understand that no two businesses have identical server needs. Our approach is personalized to suit your specific requirements, whether you have a single server or a complex, multi-server setup.',
        },

        {
          pointid: 3,

          pointTitle: 'Comprehensive Services:',

          pointLine:
            'From server setup and configuration to ongoing monitoring, security, and performance optimization, we offer end-to-end server administration services to meet your demands.',
        },

        {
          pointid: 4,

          pointTitle: '24/7 Support:',

          pointLine:
            "Servers don't adhere to a 9-to-5 schedule, and neither do we. Our round-the-clock support ensures that your servers are monitored and issues are resolved promptly, even in the dead of night.",
        },
      ],
      serviceInfoMain: 'Our Server Administration Services:',

      serviceInfo: [
        {
          topicid: 1,

          topic: 'Server Setup and Configuration:',

          topicPoints: [
            'Custom server installation and configuration tailored to your needs.',

            'OS installation, patch management, and software setup.',

            'Network and firewall configuration for security and performance.',
          ],
        },

        {
          topicid: 2,

          topic: 'Security and Data Protection:',

          topicPoints: [
            'Robust server security measures including firewalls and intrusion detection.',

            'Regular security audits and vulnerability assessments.',

            'Data backup and disaster recovery planning.',
          ],
        },

        {
          topicid: 3,

          topic: 'Performance Optimization:',

          topicPoints: [
            'Server performance analysis and tuning for optimal speed and efficiency.',

            'Load balancing and resource allocation for high availability.',

            'Scalability planning to accommodate business growth.',
          ],
        },

        {
          topicid: 4,

          topic: 'Server Monitoring and Maintenance:',

          topicPoints: [
            '24/7 server monitoring to detect and address issues in real-time.',

            'Routine maintenance tasks like updates, patches, and system checks.',

            'Proactive problem resolution to prevent downtime.',
          ],
        },

        {
          topicid: 5,

          topic: 'User and Access Management:',

          topicPoints: [
            'User account management, access control, and permissions.',

            'Implement role-based access control (RBAC) for enhanced security.',

            'Ensure compliance with data protection regulations.',
          ],
        },

        {
          topicid: 6,

          topic: 'Server Virtualization:',

          topicPoints: [
            'Virtualization solutions to optimize server resources.',

            'Implement and manage virtual machines (VMs) for cost-efficiency.',

            'Server consolidation to reduce hardware footprint.',
          ],
        },
      ],
    },
  ];
  function findServiceByName(serviceName) {
    for (let i = 0; i < serviceData.length; i++) {
      if (serviceData[i].serviceName === serviceName) {
        return serviceData[i];
      }
    }
    return null;
  }
  const serviceObj = findServiceByName(params.id);
  return (
    <Box>
      <SectionTop title="Why Codise IT??" desc="At CODISE-IT, we combine technical expertise with a customer-focused approach to provide bespoke IT solutions designed to meet your unique needs. Our services are crafted to help you achieve operational excellence, drive business growth, and maintain robust security. Partner with us to transform your IT landscape into a strategic asset."/>
      <Box>
      {serviceObj ? (
        <Box>
          <Box bgGradient="linear(to-b, rgb(229, 229, 229), rgb(255, 255, 255))">
      <Box align={'center'} maxW="1200px" mx="auto">
        <Stack direction={{ base: 'column', lg: 'row' }}>
          {/* Content Section */}
          <Stack
            flex={2}
            color={'gray.900'}
            justify={{ lg: 'center' }}
            py={{ base: 4, md: 14, xl: 14 }}
            px={{ base: 5 }}
          >
            <Stack alignItems={'start'} mb={{ base: 8, md: 20 }}>
              <Text
                fontFamily={'heading'}
                fontWeight={700}
                textTransform={'uppercase'}
                textAlign={'left'}
                fontSize={{ base: 'lg', md: 'xl' }} // Responsive font size
                color={'gray.900'}
              >
                {serviceObj.quoteLine}
              </Text>
              <Heading
                textAlign={'left'}
                color={'gray.900'}
                mb={5}
                fontSize={{ base: '2xl', md: '3xl', lg: '5xl' }} // Responsive font size
              >
                {params.id}
              </Heading>
              <Text textAlign={'left'} fontSize={{ base: 'md', md: 'xl' }} color={'gray.900'}>
                {serviceObj.mainPara}
              </Text>
            </Stack>
            <Heading
              textAlign={'left'}
              color={'gray.900'}
              mb={5}
              fontSize={{ base: 'xl', md: '2xl', lg: '4xl' }} // Responsive font size
            >
              {serviceObj.mainLine}
            </Heading>
            <AboutImg image={serviceObj.serviceImg2}/>

            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={10}>
              {serviceObj.mainpoints.map((point,index) => {
                return (
                  <Box key={index}>
                    <br />
                    <Text
                      textAlign={'left'}
                      fontFamily={'heading'}
                      fontSize={{ base: '2xl', md: '3xl' }} // Responsive font size
                      color={'gray.900'}
                      mb={3}
                      fontWeight={'600'}
                    >
                      {point.pointTitle}
                    </Text>
                    <Text
                      textAlign={'left'}
                      fontSize={{ base: 'md', md: 'xl' }} // Responsive font size
                      color={'gray.900'}
                    >
                      {point.pointLine}
                    </Text>
                  </Box>
                );
              })}
            </SimpleGrid>
          </Stack>
        </Stack>
      </Box>
    </Box>
            <Box p={4}>
      <Stack
        spacing={4}
        as={Container}
        maxW={'3xl'}
        textAlign={'center'}
        marginTop={'60px'}
      >
        <Heading fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}>
          {serviceObj.serviceInfoMain}
        </Heading>
        <Divider borderColor={'#000'} mt={3} />
      </Stack>

      <Box mt={10} textAlign={'left'} align={'center'} maxW="1200px" mx="auto">
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {serviceObj.serviceInfo.map((info,index) => {
            return (
              <HStack key={index} align={'top'} style={{borderWidth:"1px"}} p={5}>
                <Box color={'green.400'} px={2}>
                  <Icon as={CheckIcon} />
                </Box>
                {/* <hr borderWidth="1px" /> */}
                <VStack align={'start'}>
                  <Text fontWeight={600} fontSize={{ base: 'lg', md: 'xl' }}>
                    {info.topic}
                  </Text>
                  <ul>
                    {info.topicPoints.map((pt, index) => {
                      return (
                        <li key={index}>
                          <Text textAlign={'left'} fontSize={{ base: 'md', md: 'xl' }}>
                            {pt}
                          </Text>
                        </li>
                      );
                    })}
                  </ul>
                </VStack>
              </HStack>
            );
          })}
        </SimpleGrid>
      </Box>

          </Box>
        </Box>
      ) : (
        <Box>No Data Found</Box>
      )}
      </Box>
    </Box>
  );
};

export default Service;
