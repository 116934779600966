import React from 'react'
import {
  Heading,
  Box,
  Text,
  Image,
  Stack,
  Container,
  useColorModeValue,
} from '@chakra-ui/react'
import BlogList from '../../Components/BlogList'

// const BlogTags = () => {

//   return (
//     <HStack spacing={2}>
//       <h1>Gracy</h1>
//       {/* {tags.map((tag) => {
//         return (
//           <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
//             {tag}
//           </Tag>
//         )
//       })} */}
//     </HStack>
//   )
// }

// const BlogAuthor = () => {
//   return (
//     <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
//       <Image
//         borderRadius="full"
//         boxSize="40px"
//         src="https://100k-faces.glitch.me/random-image"
//         alt={`Avatar of Person`}
//       />
//       <Text fontWeight="medium">Gracy Patel</Text>
//       <Text>—</Text>
//       <Text>11/11/2024</Text>
//     </HStack>
//   )
// }

// <Flex height={'50vh'} justifyContent={'center'} alignItems={'center'}>
// </Flex>

const Blog = () => {
  return (
    <Box>
          <Box p={'30px'} bgGradient='linear(to-r,rgb(6, 7, 43) , #30298E, #30298E, #30298E, rgb(6, 7, 43) )' color={'#fff'}>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
            <Heading fontSize={{ base: '4xl', sm: '6xl' }} fontWeight={'extrabold'}>
            Our Blogs!
            </Heading>
            <Text color={'gray.100'} fontSize={{ base: 'sm', sm: 'lg' }}>
            Welcome to CODISE-IT, the leading provider of Managed IT Services for businesses of all sizes. With our comprehensive range.
            </Text>
          </Stack>
          </Box>
    
    <Container maxW={'7xl'} p="12">
      <Heading as="h1">Interesting articles updated daily</Heading>
      <BlogList />
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between">
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center">
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%">
            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Image
                borderRadius="lg"
                src={
                  'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80'
                }
                alt="some good alt text"
                objectFit="contain"
              />
            </Box>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(orange.600 1px, transparent 1px)',
                'radial(orange.300 1px, transparent 1px)',
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          textAlign={'start'}
          marginTop={{ base: '3', sm: '0' }}>
          {/* <BlogTags tags={['Engineering', 'Product']} /> */}
          
          {/* <BlogAuthor name="John Doe" date={new Date('2021-04-06T19:01:27Z')} /> */}
          <Heading marginTop="1">
            <Text textDecoration="none" _hover={{ textDecoration: 'none' }}>
            Sustainable IT practices: How tech companies can reduce their carbon footprint
            </Text>
          </Heading>
          <br/>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry&apos;s standard dummy text ever since the
            1500s, when an unknown printer took a galley of type and scrambled it to make
            a type specimen book.
          </Text>
        </Box>
      </Box>

      <BlogList />
    </Container>
    </Box>
  )
}

export default Blog