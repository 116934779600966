import React from 'react';
import './index.css';
import {
  ChakraProvider,
  Box,
  theme,
} from '@chakra-ui/react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';


// Components
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
// import Home from './Pages/Home';
import Services from './Pages/Services';
import Service from './Pages/Services/Sevice';
import About from './Pages/About';
import Blog from './Pages/Blog';
import Contact from './Pages/Contact';
import HeroSection from './Pages/Hero2';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Box>
          <Navbar />
            {/* Routes */}
            <Routes>
              <Route exact path="/" element={<HeroSection />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/services/:id" element={<Service />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          <Footer />
          </Box>
      </Router>
    </ChakraProvider>
  );
}

export default App;
