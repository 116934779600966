import React from 'react'
import {
    Box,
    Stack,
    Text,
    Image,
    useColorModeValue,
    } from '@chakra-ui/react'
import TestImage from "../../Assets/images/Patner Logo/dell-technologies-logo.svg"


const BlogList = () => {

    const Testidata = [
        {
            id:1,
            name:"John Doe",
            desi: "Content manager",
            image: TestImage
        },
        {
            id:2,
            name:"John Doe",
            desi: "Content manager",
            image: TestImage
        }
    ]

    const PriceWrapper = ({children}) => {
        
        return (
          <Box
            mb={4}
            shadow="base"
            // borderWidth="1px"
            alignSelf={{ base: 'center', lg: 'flex-start' }}
            // borderColor={useColorModeValue('gray.200', 'gray.500')}
            borderRadius={'xl'}>
            {children}
          </Box>
        )
      }


  return (
    <Box>
          {/* <VStack spacing={2} textAlign="center"> */}
            {/* <Heading as="h1" fontSize="4xl">
            Interesting articles updated daily
            </Heading> */}
            {/* <Text fontSize="lg" color={'gray.500'}>
              Start with 14-day free trial. No credit card needed. Cancel at anytime.
            </Text> */}
          {/* </VStack> */}
          <Stack
            direction={{ base: 'column', md: 'row' }}
            textAlign="center"
            justify="center"
            spacing={{ base: 4, lg: 10 }}
            py={10}>

            {
                Testidata.map((item) => {
                    return (
                        <PriceWrapper>
                            <Box
        maxW={'445px'}
        w={'full'}
        // eslint-disable-next-line react-hooks/rules-of-hooks
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        overflow={'hidden'}>
            <Box textAlign={'start'}>
            <Text
            color={'green.500'}
            textTransform={'uppercase'}
            fontWeight={800}
            fontSize={'sm'}
            letterSpacing={1.1}>
            BLOG
          </Text>
          <small>December 12, 2023</small>
            <Text color={'black.500'} fontWeight={800}>Cybersecurity best practices for 2025: Protecting your business from modern threats.</Text>
        </Box>
        <Box h={'210px'} bg={'gray.100'} mt={-6} mx={-6} mb={6} pos={'relative'}>
          <Image
            src={
              'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            }
            fill
            alt="Example"
          />
        </Box>
      </Box>
            </PriceWrapper>
            
                    )
                })
            }
          </Stack>
        </Box>
  )
}

export default BlogList