import React from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  VStack,
  Image,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import CustRec from '../../Assets/images/CustRec.png';
import { Link } from 'react-router-dom';

const HeroCard = () => {
  return (
    <Box>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
        align={'stretch'}
        maxW="1200px"
        mx="auto"
      >
        <Box
          flex="0 0 50%" // Set the width to 40%
          bg={useColorModeValue('white', 'gray.900')}
          rounded={'md'}
          p={6}
          overflow={'hidden'}
        >
          <Box bg={'gray.100'}>
            <Image
              // src={
              //   'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
              // }
              src={CustRec}
              rounded={5}
              objectFit="cover" // Ensure the image covers the box
              width="100%" // Make the image take the full width of the box
              height="100%" // Make the image take the full height of the box
              alt="Example"
            />
          </Box>
        </Box>

        <Box
          flex="0 0 50%" // Set the width to 60%
          bg={useColorModeValue('white', 'gray.900')}
          rounded={'md'}
          p={6}
          overflow={'hidden'}
        >
          <Box height={'100%'}>
            <VStack
              spacing={2}
              textAlign={'start'}
              height={'100%'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
            >
              <Heading
                fontWeight={800}
                fontSize={{ base: '1xl', sm: '3xl', md: '6xl' }}
                lineHeight={'110%'}
                textAlign={'left'}
              >
                <Text as={'span'} color={'black.500'}>
                  We will witness something great
                </Text>
              </Heading>
              <Text fontSize="lg" color={'gray.800'} mt={'4px'}>
                At CODISE-IT, we redefine excellence in IT services with
                innovative solutions tailored to meet your business needs.
                Whether you're a startup, a growing enterprise, or an
                established corporation, our expertise ensures your technology
                infrastructure is robust, scalable, and future-ready.
              </Text>

              <Stack
                spacing={{ base: 4, md: 6 }}
                direction={{ base: 'column', md: 'row' }}
                width={'100%'}
                mt={5}
              >
                  <Button
                   as={Link}
                    to="/contact"
                    rounded={'full'}
                    px={6}
                    colorScheme={'pink'}
                    bg={'#ED1651'}
                    _hover={{ bg: 'white', color:'#000', border: 'solid' }}
                  >
                    Contact Us
                  </Button>
                  <Button  as={Link}
                    to="/about" rounded={'full'} px={6} colorScheme={'gray'} bg={'gray.300'} color={'black'} _hover={{bg:'#ED1651', color:' #fff'}}>
                    About Us
                  </Button>
              </Stack>
            </VStack>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default HeroCard;
